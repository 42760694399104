@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 !important;
  
  @media (max-width: 1170px) {
    padding: 19px !important;
  }
  
  @media (max-width: 540px) {
    gap: 10px;
  }
}

.featuresMain {
  composes: baseColumn;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  @media (max-width: 1024px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-auto-flow: dense;
  gap: 0;

  @media (--viewportMedium) {
    gap: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    width: 64%;
  }
  
  @media (max-width: 1290px) {
    width: 71%;
  }
  
  @media (max-width: 1024px) {
    width: 100%;
    text-align: center;
  }
  
  @media (max-width: 1024px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  &:nth-child(even) {
    @media (--viewportMedium) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
    }
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.heroImage {
  width: 100%;
  margin-right: -83px;
  margin-top: 26px;
  
  @media (max-width: 1290px) {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.heroImage img {
  -webkit-transition: ease .3s;
  -o-transition: ease .3s;
  transition: ease .3s;
  width: 100%;
  
  @media (max-width: 1290px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}