.root {
  /* SectionContainer defaults */
  background: var(--colorBackgroundGradient);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 41px;
  padding-top: 16px;

  @media (max-width: 550px) {
    min-height: 0;
  }
}

.sectionDetails {
  width: 100%;
  max-width: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  padding-top: 44px;
}

.ctaParent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 0 !important;

  @media (max-width: 550px) {
    flex-direction: column;
  }
}

.ctaButton, .secondCtaButton {
  composes: webCTA18px from global;
  border-radius: 4px;
  padding: 16px 24px 14px;

  @media (max-width: 550px) {
    width: 187px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ctaButton {
  padding: 13px 18px 13px 18px;
  background: var(--colorPrimary);
  color: var(--colorWhite);
  transition: ease .3s;
  border: 2px solid var(--colorPrimary);
  letter-spacing: 1px;
}

.ctaButton:hover {
  text-decoration: none;
  background: var(--colorSecondary);
  border: 2px solid var(--colorSecondary);
}

.secondCtaButton {
  padding: 13px 18px 13px 18px;
  transition: ease .3s;
  color: var(--colorPrimary);
  border: 2px solid var(--colorPrimary);
  letter-spacing: 1px;
}

.secondCtaButton:hover {
  text-decoration:  none;
  color: var(--colorSecondary);
  border: 2px solid var(--colorSecondary);
  background: var(--colorWhite);
}

.highlight {
  color: var(--colorSecondary);
}

.heroTitle {
  composes: webDesktopHeadlineH1 from global;
  color: var(--colorPrimary);

  @media (max-width: 550px) {
    font-size: var(--fontSizeLarge);
  }
}
