@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }

  &:target {
    scroll-margin-top: var(--topbarHeight);
    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.sectionContent {
  padding: 30px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 30px 0 20px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
