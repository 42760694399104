.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.text p {
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeRegular);
  color: var(--colorGrayText);
  line-height: 25px;
  margin-top: -8px;
  margin-bottom: 32px;
  
  @media (max-width: 1290px) {
    font-size: var(--textPriceRegular);
    line-height: 23px;
  }
  
  @media (max-width: 1024px) {
    font-size: var(--fontSizeRegular);
    line-height: 25px;
    margin-bottom: 14px;
    max-width: 100%;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    font-size: var(--textPriceRegular);
    line-height: 23px;
  }
}

.text h3 {
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--textBlockSize);
  color: var(--colorPrimary);
}

.text h4 {
  font-weight: var(--fontWeightMedium);
  font-size: var(--textBlockSize);
  color: var(--colorPrimary);
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;

  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* center */
    margin-left: auto;
    margin-right: auto;
  }

  & > p {
    /* center */
    margin-left: auto;
    margin-right: auto;
  }
}

.alignRight {
  text-align: right;
  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* push to right */
    margin-left: auto;
  }

  & > p {
    /* push to right */
    margin-left: auto;
  }
}

.heroTitle {
  display: none;
  font-weight: var(--fontWeightHeavy);
  font-size: var(--fontSizeExtraLarge);
  line-height: 53px;
  color: var(--colorPrimary);
  margin-bottom: 24px;
  
  @media (max-width: 1290px) {
    font-size: var(--fontSizeLarge);
    line-height: 35px;
  }
  
  @media (max-width: 1024px) {
    font-size: var(--fontSizeExtraLarge);
    line-height: 53px;
    text-align: center;
  }

  @media (max-width: 768px) {
    font-size: var(--fontSizeLarge);
    line-height: 35px;
  }
}

.highlight {
  color: var(--colorSecondary);
}

.customButton {
  display: none;
  padding-top: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;

  @media (max-width: 1024px) {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 550px) {
    .buttonContainer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
  }
}

.buttonContainer {
  @media (max-width: 550px) {
    width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
  }
}

.firstCtaButton,
.secondCtaButton {
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeRegular);
  line-height: 18px;
  border-radius: 4px;
  -webkit-transition: ease .3s;
  -o-transition: ease .3s;
  transition: ease .3s;
  
  @media (max-width: 1290px) {
    font-size: var(--textPriceRegular);
  }
  
  @media (max-width: 1024px) {
    font-size: var(--fontSizeRegular);
  }
  
    @media (max-width: 768px) {
      font-size: var(--textPriceRegular);
    }
}

.firstCtaButton {
  color: var(--colorWhite);
  background: var(--colorPrimary);
  margin-right: 16px;
  padding: 16px 24px 14px;
  
  @media (max-width: 550px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.firstCtaButton:hover {
  text-decoration: none;
  background-color: var(--colorSecondary);
}

.secondCtaButton:hover {
  text-decoration: none;
  border: 2px solid var(--colorSecondary);
  color: var(--colorSecondary);
}

.secondCtaButton {
  color: var(--colorPrimary);
  border: 2px solid var(--colorPrimary);
  padding: 14px 24px 12px;
}

.highlightedParagraph {

}

.heroImage {
  display: none;
  display: -webkit-flex;
  width: 54%;
  margin-right: 65px;
  
  img {
    width: 740px;
    -webkit-transition: ease .3s;
    -o-transition: ease .3s;
    transition: ease .3s;
    
    @media (max-width: 1290px) {
      width: 500px;
    }

    @media (max-width: 550px) {
      width: 100%;
    }
  }
  
  @media (max-width: 1290px) {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-right: 0;
    margin-left: 5px;
  }
}