@import '../../../../styles/customMediaQueries.css';

.root{
  background-color: var(--colorWhite);
  border-bottom: var(--borderBottom);
}

.carouselContainer {
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  padding: 37px 0 0 0;

  &:hover .carouselArrows {
    opacity: 1;
  }

  /* smartphones, touchscreens: we don't need to show arrows */
  @media (any-hover: none) and (pointer: coarse) {
    &:hover .carouselArrows {
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 16px 0 0 0;
  }
}

.title {
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeLarge);
  line-height: 38px;
  text-transform: capitalize;
  color: var(--colorPrimary);

  @media (max-width: 425px) {
    font-size: var(--fontSizeMedium);
    padding-right: 68px;
  }

  @media (max-width: 334px) {
    font-size: var(--textBlockSize);
    line-height: 28px;
  }
}

.ctaButton {
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeRegular);
  color: var(--colorSecondary);
  margin-top: 0 !important;
}

.locationLink {
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeMedium);
  color: var(--colorPrimary);
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  opacity: 0;

  @media (max-width: 425px) {
    font-size: var(--fontSizeSmall);
  }
}

.locationLink:hover {
  text-decoration: none;
  color: var(--colorSecondary);
}

.carouselArrows {
  opacity: 0;
  z-index: 2;
  transition: all ease-in-out 500ms;
}

.carouselArrowPrev,
.carouselArrowNext {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;

  width: 48px;
  height: 48px;
  padding: 0px 0 4px 0;
  margin-top: -64px;
  border-radius: 100%;
  border: none;

  background-color: lightgrey;
  opacity: 0.9;
  font-size: var(--fontSizeLarge);
  color: black;
  transition: all ease-in-out 100ms;

  &:hover {
    opacity: 1;
    cursor: pointer;
    background: black;
    color: white;
  }
}

.carouselArrowPrev {
  left: 48px;
}

.carouselArrowNext {
  right: 48px;
}
.notEnoughBlocks {
  @media (--viewportMedium) {
    display: none;
  }
}

.baseCarousel {
  display: flex;
  flex-wrap: nowrap;
  width: var(--carouselWidth);
  padding-bottom: 24px;
  padding-left: 0px;
  padding-top: 5px;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.oneColumn,
.twoColumns,
.threeColumns,
.fourColumns {
  composes: baseCarousel;
}

.block {
  flex: 0 0 auto;
  /* 64px = horizontal layout paddings */
  width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

  margin-right: 16px;
  scroll-snap-align: center;

  transform: translateX(32px);

  h3 {
    composes: webDesktopHeadlineH5 from global;
    font-weight: var(--fontWeightBold) !important;
    margin: 16px 5px 0 0;
    padding: 0 11px;

    @media (max-width: 425px) {
      font-size: var(--textCarousel);
    }
  }

  h5 {
    font-weight: var(--fontWeightRegular);
    color: var(--colorGrayText);
    margin: 4px 5px 0 0;
    line-height: 1.5;
    padding: 0 11px;
  }

  h4 {
    margin: 24px 5px 0 0;
    padding: 0 11px;
  }

  &:last-of-type {
    padding-right: 32px;
    /* 32px (padding-right above) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
  }

  @media (--viewportMedium) {
    margin-right: 24px;
  }

  @media (min-width: 1200px) {
    /* Offset the start of the carousel so it follows the global grid layout (e.g. (1400 - 1200) / 2 = 100px) */
    /* Removing this uses the full page width for the slider pane */
    transform: translateX(
      max(calc((var(--carouselWidth) - var(--contentMaxWidthPages)) / 2 + 0px), 0px)
    );

    &:last-of-type {
      padding-right: 32px;
      width: calc(
        min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px
        ); /* 32px (padding-right above) */
      }
    }

  @media (max-width: 425px) {
    width: 112px;

    &:last-of-type {
      padding-right: 32px;
      /* 32px (padding-right above) */
      width: 144px;
    }
  }
}

.oneColumn .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

    &:last-of-type {
      /* 32px (padding-right) */
      width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
    }
  }
}

.twoColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (one gutter á 32px) / 2 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 32px) / 2);

    &:last-of-type {
      /* 32px (padding-right above) / 2 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 2);
    }
  }
}

.threeColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (two gutters á 32px) / 3 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 64px) / 3);

    &:last-of-type {
      /* 32px (padding-right above) / 3 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 3);
    }
  }
}

.fourColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 96px (three gutters á 32px) / 4 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 16px) / 4);

    &:last-of-type {
      /* 32px (padding-right above) / 4 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - -64px) / 4);
    }
  }
}

.sectionDetails {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  
  @media (max-width: 349px) {
    padding: 0 19px;
  }
}

.headerContainer{
  width: 100%;
  max-width: 1129px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media {

  @media (max-width: 425px) {height: 140px;}
}

#section-homes img {
  height: 100px; /* Set the height */
  object-fit: cover; /* Maintain aspect ratio while filling the height */
}
