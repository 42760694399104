@import '../../../../styles/customMediaQueries.css';

.root{
  background-color: var(--colorWhite);
  border-bottom: var(--borderBottom);
}

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 32px;
  row-gap: 20px;
  margin: 0 auto;
  padding: 34px 0 28px 0;

  @media (max-width: 1190px) {
    padding: 32px 24px;
  }

  @media (max-width: 349px) {
    padding: 32px 19px;
  }
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  width: 100%;
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.sectionDetails {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  @media (max-width: 349px) {
    padding: 0 19px;
  }
}

.headerContainer {
  width: 100%;
  max-width: 1129px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSizeLarge);
  line-height: 38px;
  text-transform: capitalize;
  color: var(--colorPrimary);
  padding-top: 13px;
  
  @media (max-width: 768px) {
    font-size: var(--fontSizeMedium);
  }
  
  @media (max-width: 425px) {
    width: 76%;
  }
  
  @media (max-width: 334px) {
    font-size: var(--textBlockSize);
    line-height: 28px;
  }
}

.ctaButton {
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--fontSizeRegular);
  line-height: 25px;
  color: var(--colorSecondary);
  margin-top: 0 !important;
  padding-top: 14px;
  
  @media (max-width: 768px) {
    font-size: var(--textPriceRegular);
  }
}

.block {
  background: var(--colorWhite);
  box-shadow: var(--blockShadow);
  border-radius: 8px;
  padding-bottom: 20px;
  width: 102%;
  position: relative;
  
  h3, h4, h5 {
    padding: 0 11px;
  }

  h3 {
    composes: webDesktopHeadlineH5 from global;
    font-weight: var(--fontWeightBold) !important;
    margin: 16px 5px 0 5px;
  }

  h5 {
    font-weight: var(--fontWeightRegular);
    color: var(--colorGrayText);
    margin: 4px 5px 0 5px;
    line-height: 1.5;
  }

  h4 {
    margin: 24px 5px 0 5px;
  }

  img {
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
  }
}

.blockContainer {
  p {
      margin-top: 5px;
  }
}

.columnContainer {
  padding: 0 0;
}

.text p {
  margin-bottom: 0;
}

.media {
  height: 200px;
}